import authService from '../components/api-authorization/AuthorizeService'
import moment from 'moment'

export const getTurnosHistoricos = async (fechaDesde, fechaHasta) => {
    const fechaD = moment(fechaDesde).format();
    const fechaH = moment(fechaHasta).format();
    const token = await authService.getAccessToken();
    const response = await fetch(`api/TurnosPorBoxHistorico/MostrarTurnosHistoricos?FechaDesde=${fechaD}&FechaHasta=${fechaH}`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    const data = await response.json();
    return data;
}
// export const getTurnosHistoricos = async(fechaDesde, fechaHasta) => {
//     const fechaD = moment(fechaDesde).format();
//     const fechaH = moment(fechaHasta).format();
    
//     const token = await authService.getAccessToken();
//     const response = await fetch(`api/TurnosPorBoxHistorico/MostrarTurnosHistoricos/FechaDesde=${fechaD}&FechaHasta=${fechaH}`, {
//         headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
//     });

//     // if (response.status === 401) {
//     //     await authService.signIn();
//     // }

//     const data = await response.json();
//     return data;
// }