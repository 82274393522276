import React, { useState, useEffect, useContext, useRef, forwardRef } from 'react'
import { getMostrarTurnos } from '../helpers/getMostrarTurnos'
import { useSpring, useSprings, config, useTransition, animated } from 'react-spring';
import { useReactToPrint } from "react-to-print";
import moment from 'moment';

import useSound from 'use-sound';
import sound from '../assets/sounds/tin_sms.mp3';

import { GeneralActions } from './Layout';


const ImpresionTurnoComponent = forwardRef(({nroTurno, caja}, ref) => (
	<div ref={ref} className="turno-imprimir">
		<p className="nombre-caja">{caja}</p>
		<p className="nro-turno">{nroTurno}</p>
		<p className="fecha-impresion">{ moment().format('LL HH:mm')}</p>
	</div>
));

export const MostrarTurnosActual = ({turnoImprimir, siguienteTurno, finalizaTurnos, handleFinalizarTurnos}) => {
    const [turnos, setTurnos] = useState([]);
    const [play] = useSound(sound);

    const [impresionTurno, setImpresionTurno] = useState({ caja: '', nroTurno: 0 });

    const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		bodyClass: 'body-class-impresion'
	});

    const { toggleShowMenu, handleShowBgImage } = useContext(GeneralActions);

    const [listadoTurnos, api] = useSprings(
        turnos.length, 
        index => ({to: { y: 0, opacity: 1 }, from: { y: -150, opacity: 0 }, config: config.stiff})
    )

    useEffect(() => {
        toggleShowMenu();
        handleShowBgImage(true);

        getMostrarTurnos().then(setTurnos);

        return () => handleShowBgImage(false);
    }, []);

    useEffect(() => {
        if (siguienteTurno) {
            let numeroEditadoIndex = turnos.findIndex(a => a.boxAtencionID == siguienteTurno.boxAtencionID);
            let numeroEditado = turnos.find(a => a.boxAtencionID == siguienteTurno.boxAtencionID);
            let turnosEditados = [...turnos];
            turnosEditados.splice(numeroEditadoIndex, 1, { ...numeroEditado, numero:  siguienteTurno.numero })
            setTurnos(turnosEditados)
            api(index => index === numeroEditadoIndex ? {to: { y: 0, opacity: 1, backgroundColor: 'white'}, from: { y: -50, opacity: 0.7, backgroundColor: 'green'},
            config: {duration: 2000}
            
        } : {});
            play();
        }
    }, [siguienteTurno])

    useEffect(() => {
        if (finalizaTurnos) {
            let turnosEditados = turnos.map(t => ({...t, numero: 0}));
            setTurnos(turnosEditados);
            handleFinalizarTurnos();
        }
    }, [finalizaTurnos]);

    useEffect(() => {
        console.log({turnoImprimir,impresionTurno})
        if (turnoImprimir) {
            const _turnoImprimir = { caja: turnoImprimir.boxAtencionNombre, nroTurno: turnoImprimir.numero.toString().padStart(5, "0") };
            setImpresionTurno(_turnoImprimir);
            setTimeout(handlePrint, 500);
        }
    }, [turnoImprimir]);

    return (
        <>
            <ImpresionTurnoComponent ref={componentRef} caja={impresionTurno?.caja} nroTurno={impresionTurno?.nroTurno} />
            <div className="h-100vh d-flex flex-wrap flex-column flex-sm-row justify-content-center align-items-center gap-4">
                {
                    listadoTurnos.map((style, index) => {
                        const turno = turnos[index];
                        return (
                            <animated.div 
                                key={turno.boxAtencionID} 
                                style={style} 
                                className="card custom-card turnosBox border rounded"
                            >
                                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                    <h3 className="card-title textoRojo">{turno.boxAtencionNombre}</h3>
                                    <p className="card-title turnosActuales">{turno.numero.toString().padStart(5,"0")}</p>
                                </div>
                            </animated.div>
                        )
                    })
                }
            </div>
        </>
    )
}