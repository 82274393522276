import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Loading from './Loading';

import { FinalizarTurnos } from '../helpers/finalizarTurnos';

import { useFetchBoxes } from '../hooks/useFetchBoxes';

import { GeneralActions } from './Layout';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const swalWithBootstrapButtons = Swal.mixin({
	customClass: {
		confirmButton: 'btn btn-success swal',
		cancelButton: 'btn btn-danger swal'
	},
	confirmButtonText: 'Aceptar',
	cancelButtonText: 'Cancelar',
	showCancelButton: true,
	buttonsStyling: false
});

const MySwal = withReactContent(swalWithBootstrapButtons)

export const Home = ({ finalizarTurnos }) => {
	localStorage.removeItem("BoxSeleccionado");

	const history = useHistory();

	const { boxes, loading } = useFetchBoxes(true);

	const { toggleShowMenu } = useContext(GeneralActions);

	useEffect(() => {
		toggleShowMenu();
	}, [])

	const handleFinalizar = () => {
		MySwal.fire({
			icon: 'question',
			html: <strong>¿Está seguro de finalizar los turnos de cada Box?</strong>
		}).then((respuesta) => {
			// return MySwal.fire(<p>Shorthand works too</p>)
			if(respuesta.isConfirmed){
				FinalizarTurnos()
					.then(respuesta => {
						if (respuesta == false) {
							MySwal.fire({
								icon: 'error',
								html: <p>No se puede Finalizar los Turnos ya que existen <strong>Turnos Pendientes</strong>.</p>,
								showCancelButton: false,
								showConfirmButton: false,
								timer: 2500
							});

							return;
						}
						
						finalizarTurnos();
						MySwal.fire({
							icon: 'success',
							html: <p>Los Turnos fueron <strong>Finalizados Correctamente</strong>.</p>,
							showCancelButton: false,
							showConfirmButton: false,
							timer: 2000
						})
					});
			}
		})
	}

	const SelectBoxes = ({boxes, finalizar}) => {
		if(boxes.length === 0) {
			return ( 
				<div className="alert alert-secondary my-4" role="alert">
					No tenes <strong>Cajas</strong> asignadas.
				</div>
			)
		}

		if(boxes.length === 1) {
			history.push('/manejo-turnos-box/' + boxes[0].boxAtencionID);
			return null
		}
		
		return (
			<React.Fragment>
				<div className="row row-cols-1 row-cols-md-2 g-4 tarjetasInicio pt-5">
					{boxes.map(box =>
						<Link key={box.boxAtencionID} to={"/manejo-turnos-box/" + box.boxAtencionID}>
							<div className="card">
								<div className="card-header-first rounded text-center p-3">
									<h2 className="card-title">{box.boxAtencionNombre}</h2>
									<h5 className="card-text">Abreviación: {box.boxAtencionAbreviacion}</h5>
								</div>
								<div className="card-body card-section home rounded p-4 text-center">
									<p className="card-text">
										Seleccione esta opción para ver los turnos de {box.boxAtencionNombre}.
									</p>
								</div>
							</div>
						</Link>
					)}
				</div>

				<hr className="mt-4 mb-4" />
				<div className="text-center">
					<button onClick={finalizar} className="btn btn-success btn-lg mb-5">Finalizar Turnos</button>
				</div>
			</React.Fragment>	
		)
	}

	return (
		<>
			<Loading loading={loading} />
			<SelectBoxes boxes={boxes} finalizar={handleFinalizar} />
		</>
	);
}