import authService from '../components/api-authorization/AuthorizeService'

export const obtenerTurnosBox = async (boxAtencionID) => {
    console.log({boxAtencionID})
    const token = await authService.getAccessToken();
    const response = await fetch('api/TurnosPorBox/ObtenerTurnosPendientes/' + boxAtencionID, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    
    if (response.status === 401) {
        await authService.signIn();
    }

    const data = await response.json();
    // console.log({data});
    return data;
}