import React from 'react';
import { deleteBoxes } from '../helpers/getBoxes';
import { useFetchBoxes } from '../hooks/useFetchBoxes';
// import { ModalForm } from './Modal';

export const BoxAtencion = () => {
    const { boxes, loading, deleteBoxEstado } = useFetchBoxes();

    const handleDelete = async (boxId) => {
        const respuestaEliminacion = await deleteBoxes(boxId);
        deleteBoxEstado(respuestaEliminacion.boxAtencionID)
    }

    
    const renderBoxesTable = (boxes) => {
        return (
            <>
                {boxes.map(box =>
                    <div key={box.boxAtencionID} className="card w-25 d-inline-block">
                        <div className="card-body text-center">
                            <h3 className="card-title">{box.boxAtencionNombre}</h3>
                            <p className="card-text">Abreviación: {box.boxAtencionAbreviacion}</p>
                            {/* <a onClick={() => handleEdit(box)} className="btn btn-primary m-2">Editar</a> */}
                            {/* <a href="#" className="btn btn-primary m-2">Editar</a> */}
                            <a onClick={() => handleDelete(box.boxAtencionID)} className="btn btn-danger m-2">Eliminar</a>
                        </div>
                    </div>
                )}
            </>
        );
    }



    let contents = loading ? <p><em>Loading...</em></p> : renderBoxesTable(boxes);

    return (
        <div>
            {/* <ModalForm show={showModal} handleClose={() => setShowModal(false)} contenido={contenido} /> */}
            <h4 id="tabelLabel" className="mt-4" >Boxes de Atención</h4>
            <br />
            {contents}
        </div>
    );
}