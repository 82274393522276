
import React from 'react';

const Loading = ({ loading }) => {

    return loading 
        ?   (<div className="text-center loading-container">
                <div className="spinner-border" role="status" style={{ width: 50, height: 50, color: '#EA384D' }} >
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>)
        :       null;
}

export default Loading;