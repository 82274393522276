import {useState, useEffect} from 'react'
import { getBoxes } from '../helpers/getBoxes';

export const useFetchBoxes = (filtrarUsuario = false) => {
    const [state, setstate] = useState({ boxes: [], loading: true });

    const deleteBoxEstado = (boxAtencionID) => {
        const boxesActivos = state.boxes.filter(p => p.boxAtencionID !== boxAtencionID);
        setstate({boxes: boxesActivos})
    }

    useEffect(() => {
        getBoxes(filtrarUsuario)
            .then(respuesta => {
                setstate({
                    boxes: respuesta,
                    loading: false
                });
            });
    }, [])

    return {boxes: state.boxes, loading: state.loading, deleteBoxEstado}
}