import React, { useState, useEffect, useRef, forwardRef, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { ArrowsExpandIcon, XIcon } from '@heroicons/react/outline';

import { solicitarTurno } from '../helpers/solicitarTurno';
import { useFetchBoxes } from '../hooks/useFetchBoxes';

import { GeneralActions } from './Layout';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

export const SolicitarTurnoCliente = ({nuevoTurnoAgregar}) => {
	const history = useHistory();
	const { toggleShowMenu, setFullScreen, activeFullScreen } = useContext(GeneralActions);
	const [disabledButton, setDisabledButton] = useState(false);

	useEffect(() => {
		toggleShowMenu();
	}, [])

	const { boxes } = useFetchBoxes();

	const handleSolicitarTurno = async (boxAtencionID) => {
		MySwal.fire({
			icon: 'info',
			html: 
				<div>
					<p><strong>Generando turno...</strong></p>
				</div>,
			showCancelButton: false,
			showConfirmButton: false,
			allowEscapeKey: false,
			allowOutsideClick: false
		})

		setDisabledButton(true);
		const nuevoNroTurno = await solicitarTurno(boxAtencionID);
		if (nuevoNroTurno !== 0) {
			nuevoTurnoAgregar(boxAtencionID);
			setDisabledButton(false);

			MySwal.fire({
				icon: 'success',
				html: 
					<div>
						<p><strong>El Turno se Genero correctamente.</strong></p>
						<span>Imprimiendo...</span>
					</div>,
				timer: 2000,
				showCancelButton: false,
				showConfirmButton: false,	
				didOpen: () => {
					MySwal.showLoading();
				},
				allowEscapeKey: false,
				allowOutsideClick: false
			})
		}
	}

	return (
		<>
			{
				boxes
				?	<div className={`row row-cols-1 row-cols-sm-2 row-cols-md-${boxes.length > 3 ? '2' : boxes.length} g-4 tarjetasInicio h-100vh align-items-center p-4 p-sm-0`} >
						{boxes.map(box =>
							<a className="mt-0" onClick={() => !disabledButton && handleSolicitarTurno(box.boxAtencionID)} key={box.boxAtencionID} disabled={disabledButton}>
								<div className="card solicitarTurno card-section-solicitar rounded">
									<div className="card-body p-4 alineacionVertical bolder text-center">
										<h5 className="card-text text-uppercase title">Solicitar turno</h5>
										<h2 className="card-title">{box.boxAtencionNombre}</h2>
									</div>
								</div>
							</a>
						)}
					</div>
				:	<div className={`row g-4 tarjetasInicio h-100vh align-items-center p-4 p-sm-0`} >
						<a className="mt-0" onClick={() => history.push('/')}>
							<div className="card solicitarTurno card-section-solicitar rounded">
								<div className="card-body p-4 alineacionVertical bolder text-center">
									<h2 className="card-text text-uppercase title text-danger">ACCESO DENEGADO AL USUARIO</h2>
									<h5 className="card-title">VOLVER AL INICIO</h5>
								</div>
							</div>
						</a>
					</div>
			}
			{/* <div className={`row row-cols-1 row-cols-sm-2 row-cols-md-${boxes.length > 4 ? '4' : boxes.length} g-4 tarjetasInicio h-100vh align-items-center p-4 p-sm-0`} >
				{boxes.map(box =>
					<a className="mt-0" onClick={() => !disabledButton && handleSolicitarTurno(box.boxAtencionID)} key={box.boxAtencionID} disabled={disabledButton}>
						<div className="card solicitarTurno card-section-solicitar rounded">
							<div className="card-body p-4 alineacionVertical bolder text-center">
								<h5 className="card-text text-uppercase title">Solicitar turno</h5>
								<h2 className="card-title">{box.boxAtencionNombre}</h2>
							</div>
						</div>
					</a>
				)}
			</div> */}
		 	{	
				activeFullScreen
				? 	<button className="btn position-absolute" style={{ bottom: 20, right: 20, zIndex: 999, backgroundColor: 'white' }} onClick={() => setFullScreen(false)}>
						<XIcon width={25} />
					</button>
				: 	<button className="btn position-absolute" style={{ bottom: 20, right: 20, zIndex: 999, backgroundColor: 'white' }} onClick={setFullScreen}>
						<ArrowsExpandIcon width={25} />
					</button>
			}
		</>
	);
}