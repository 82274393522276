import React, { Component, useState, useEffect } from 'react';
import { Route } from 'react-router';
import 'moment/locale/es';

import { Layout } from './components/Layout';
import { Home } from './components/Home';
// import { FetchData } from './components/FetchData';
import { BoxAtencion } from './components/BoxAtencion';
// import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'
import { ManejoTurnosPorBox } from './components/ManejoTurnosPorBox';
import { SolicitarTurnoCliente } from './components/SolicitarTurnoCliente';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { MostrarTurnosActual } from './components/MostrarTurnosActual';
import { ConsultaTurnos } from './components/ConsultaTurnos';

export const App = () => {
	const [connection, setConnection] = useState();

	const [ultimoTurnoSolicitado, setUltimoTurnoSolicitado] = useState()
	
	const [siguienteTurno, setSiguienteTurno] = useState();

	const [finalizaTurnos, setfinalizaTurnos] = useState(false)

	const crearConexionSignalR = async (time = 0) => {
		try {
			if(time < 5) {
				const _connection = new HubConnectionBuilder()
					.withUrl("/turnos")
					.build();

				_connection.on("SolicitudTurno", (boxID, turnoPendiente) => {
					setUltimoTurnoSolicitado(turnoPendiente);
					setTimeout(() => setUltimoTurnoSolicitado(null), 1000);
				});
				_connection.on("SiguienteTurno", (boxID, ultimoTurnoAtendido) => {
					setSiguienteTurno({boxAtencionID: boxID, numero: ultimoTurnoAtendido});
				});
				_connection.on("Finalizar", () => {
					setfinalizaTurnos(true);
				});

				await _connection.start();
				setConnection(_connection);
			}
		 
		} catch (error) {
			console.log("Error Function: crearConexionSignalR → ", error);
			crearConexionSignalR(time + 1);
		}
	}

	const nuevoTurnoAgregar = async(boxAtencionID) => {
		try {
			await connection.invoke("VerTurnoSolicitado", boxAtencionID);
		} catch(error) {
			console.error('Error Function: "nuevoTurnoAgregar" → ', error);
			crearConexionSignalR();
		}
	}

	const mostrarSiguiente = async(boxAtencionID) => {
		try {
			await connection.invoke("MostrarSiguienteTurno", boxAtencionID);			
		} catch (error) {
			console.error('Error Function: "mostrarSiguiente" → ', error);
			crearConexionSignalR();
		}
	}

	const finalizarTurnos = async() => {
		try {
			await connection.invoke("FinalizarTurnos");	
		} catch (error) {
			console.error('Error Function: "finalizarTurnos" → ', error);
			crearConexionSignalR();
		}
	}

	const turnosFinalizados = () => setfinalizaTurnos(false);


	useEffect(() => {
		crearConexionSignalR()
	}, [])


	return (
		<Layout>
			<AuthorizeRoute exact path='/' >
				<Home finalizarTurnos={finalizarTurnos} />
			</AuthorizeRoute>
			<AuthorizeRoute path='/box-atencion' component={BoxAtencion} />
			<AuthorizeRoute path='/solicitar-turno-cliente'>
				<SolicitarTurnoCliente nuevoTurnoAgregar={nuevoTurnoAgregar} />
			</AuthorizeRoute>
			<AuthorizeRoute path='/manejo-turnos-box/:id'>
				<ManejoTurnosPorBox ultimoTurnoSolicitado={ultimoTurnoSolicitado} mostrarSiguiente={mostrarSiguiente} finalizaTurnos={finalizaTurnos} />
			</AuthorizeRoute>
			<AuthorizeRoute path='/mostrar-turnos-actuales'>
				<MostrarTurnosActual turnoImprimir={ultimoTurnoSolicitado} siguienteTurno={siguienteTurno} finalizaTurnos={finalizaTurnos} handleFinalizarTurnos={turnosFinalizados} />
			</AuthorizeRoute>
			<AuthorizeRoute path='/consulta-turnos'>
				<ConsultaTurnos />
			</AuthorizeRoute>
			<Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
		</Layout>
	);
}