import authService from '../components/api-authorization/AuthorizeService'

export const getBoxes = async (filtrarUsuario = false) => {
    const token = await authService.getAccessToken();
    const response = await fetch(!filtrarUsuario ? 'api/boxatencion' : 'api/boxatencion?FiltrarUsuario=true', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    
    if (response.status === 401) {
        await authService.signIn();
    }
    if (response.ok === false) {
        return response.ok;
    }

    const data = await response.json();
    return data;
}


export const deleteBoxes = async (id) => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/boxatencion/'+ id, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        method: "DELETE"
    });

    if (response.status === 401) {
        await authService.signIn();
    }
    
    const data = await response.json();
    return data;
}


export const getBox = async (id) => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/boxatencion/' + id, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    
    if (response.status === 401) {
        await authService.signIn();
    }

    const data = await response.json();
    return data;
}