import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSpring, useTransition, animated, config } from 'react-spring';

import Loading from './Loading';

import { ArrowRightIcon } from '@heroicons/react/solid';

import { getBox } from '../helpers/getBoxes';
import { obtenerTurnosBox } from '../helpers/obtenerTurnosBox';
import { SiguienteTurno } from '../helpers/siguienteTurno';
// import { transform } from 'lodash';

export const ManejoTurnosPorBox = ({ ultimoTurnoSolicitado, mostrarSiguiente, finalizaTurnos }) => {
    let { id } = useParams();

    const [box, setbox] = useState({});
    const [loading, setLoading] = useState(true);

    const [turnosPendiente, setTurnosPendiente] = useState([]);
    const [turnoActual, setTurnoActual] = useState({ numero: 0 });

    useEffect(() => {
        getBox(id)
            .then(respuesta => {
                localStorage.setItem("BoxSeleccionado", JSON.stringify(respuesta))
                setbox(respuesta)
            })

        obtenerTurnosBox(id)
            .then(respuestaturno => {
                const _turnoActual = respuestaturno.find(({estado}) => estado === 'actual');
                const _turnosPendientes = respuestaturno.filter(({estado}) => estado === 'pendiente');
                setTurnoActual(_turnoActual);
                setTurnosPendiente(_turnosPendientes);
                setLoading(false);
            })
    }, []);


    useEffect(() => {
        if (ultimoTurnoSolicitado && ultimoTurnoSolicitado?.boxAtencionID == id) {
            setTurnosPendiente([...turnosPendiente, ultimoTurnoSolicitado])
        }
    }, [ultimoTurnoSolicitado])

    useEffect(() => {
        if (finalizaTurnos) {
            setTurnoActual([{ numero: 0, estado: "actual" }])
        }
    }, [finalizaTurnos]);

    const solicitarSiguienteTurno = () => {

        SiguienteTurno(id)
            .then(respuestaSiguiente => {
                
                const _turnosPendientes = turnosPendiente.slice(1, turnosPendiente.length);
                const _turnoActal = turnosPendiente[0];
                _turnoActal.estado = 'actual';

                setTurnoActual(_turnoActal);
                setTurnosPendiente(_turnosPendientes);

                mostrarSiguiente(box.boxAtencionID)
            })
    }

    const solicitarMismoTurno = () => {

        mostrarSiguiente(box.boxAtencionID)

    }

    const TurnosPendientes = ({turnos}) => {
        if(turnos.length > 0) {

            const cantTurnosMostrar = 8;
            const turnosSobrantes = turnos.length > cantTurnosMostrar ? turnos.slice(cantTurnosMostrar).length : 0;

            return (
                <React.Fragment>
                    <h5 className="mb-3">Turnos Pendientes</h5>
                    {turnos.slice(0, cantTurnosMostrar).map(turno =>
                        <div key={turno.numero} className="border rounded turnosBox p-1 mb-2">
                            <h3 className="m-0 py-1 nroTurnoPendiente">
                                <em>Nro.:</em> {turno.numero.toString().padStart(5, "0")}
                            </h3>
                        </div>
                    )}
                    {turnosSobrantes > 0 && <div className="alert alert-secondary" role="alert"><strong>+{turnosSobrantes}</strong> {turnosSobrantes > 1 ? 'turnos pendientes' : 'turno pendiente'}</div>}
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <h5 className="mb-3">&nbsp;</h5>
                <div className="alert alert-secondary" role="alert">No se encontraron <strong>Turnos Pendientes.</strong></div>
            </React.Fragment>
        )
    }

    return (
        <div className="pt-5" >
            <Loading loading={loading} />
            <h1 className="textoRojo">{box.boxAtencionNombre}</h1>
            <hr className="hr-BoxTurnos" />
            <div className="row text-center mt-4">
                <div className="col-md-4 order-md-1 order-2">
                <TurnosPendientes turnos={turnosPendiente} />
                    
                </div>

                <div className="col-md-8 order-md-2 order-1">
                    <h5 className="mb-3">Turno Actual</h5>
                    
                    <div key={turnoActual.numero} className="border rounded turnosBox p-3">
                        <h1 className="m-0 nroTurnoActual textoRojo">
                            <em> Nro.: </em>{turnoActual.numero.toString().padStart(5, "0")}
                        </h1>
                    </div>

                    <button 
                        disabled={turnosPendiente.length === 0} 
                        onClick={solicitarSiguienteTurno}
                        className="btn btn-success btn-lg d-block w-100 py-3 my-3 d-flex justify-content-center align-items-center gap-1"
                    >
                        Siguiente Turno <ArrowRightIcon width={25} />
                    </button>

                    <button 
                        disabled={turnoActual.numero === 0} 
                        onClick={solicitarMismoTurno}
                        className="btn btn-primary btn-lg d-block w-100 py-3 my-3 d-flex justify-content-center align-items-center gap-1 text-uppercase"
                    >
                        Solicitar mismo turno
                    </button>

                    <hr className="d-block d-md-none" />
                </div>
            </div>
        </div>
    )
}