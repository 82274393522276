import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { getTurnosHistoricos } from '../helpers/getTurnosHistoricos';
import moment from 'moment';
import '../custom.css'
import 'bootstrap/dist/css/bootstrap.css';
import { Bar } from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import { animated } from 'react-spring';

export const ConsultaTurnos =() => {

  const [box, setBox] = useState();
  const [turnosTotalesPorBox, setTurnosTotalesPorBox] = useState();
  const [turnosTotales, setTurnosTotales] = useState(0);
  const [filtros, setFiltros] = useState({
    fechaDesde: moment().startOf("month").format("yyyy-MM-DD"),
    fechaHasta: moment().endOf("month").format("yyyy-MM-DD")
  });

const obtenerTurnos = useCallback(async (fechaDesde, fechaHasta) => {
  try{
    await getTurnosHistoricos(fechaDesde, fechaHasta).then(response => {
      var respuesta = response;
      var nombreBox = [], turnos = [];
      let totalTurnos = 0;
      respuesta.map(turno => {
        nombreBox.push(turno.boxAtencionNombre);
        turnos.push(turno.numero);
        totalTurnos += turno.numero;
      });
      setBox(nombreBox);
      setTurnosTotalesPorBox(turnos);
      setTurnosTotales(totalTurnos);
    })
  } catch(error) {
    console.error('Ocurrio un error en Turnos - filtrarTurnos: \n', error);
  }
})

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFiltros({...filtros, [name]: value})
  };

  useEffect(() => { 

    obtenerTurnos(filtros.fechaDesde, filtros.fechaHasta) 

  }, [filtros])

  const options = {
    plugins:{
      legend:{display: false}
    }
  }

  Chart.defaults.font.size = 18

  const data = {
		labels: box,
		datasets: [{
				label: 'TURNOS TOTALES',
				data: turnosTotalesPorBox,
				backgroundColor: [
				 'rgba(228, 54, 54, 0.7)',
				],
				borderColor: [
				 'rgba(228, 54, 54)',
				],
				borderWidth: 1
		}]
	 }
  return (
    <Fragment>
      <form className='formulario' action="">
      <div>
      <p className='parrafoFechaDesde'>Fecha desde</p>
      <input className='inputFechaDesde' type="date" value={filtros.fechaDesde} onChange={handleChange} name="fechaDesde" variant="standard"/>
      </div>
      <div>
      <p className='parrafoFechaHasta'>Fecha hasta</p>
      <input className='inputFechaHasta' type="date" value={filtros.fechaHasta} onChange={handleChange} name="fechaHasta" variant="standard" />
      </div>
      </form>
      <hr />
      <h2 className='titulo-turnos-historicos'>TURNOS TOTALES: {turnosTotales}</h2>
      <Bar data={data} options={options}/>
    </Fragment>
  )
}