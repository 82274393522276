import React, { Component, useEffect, useState, useContext } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import authService from './api-authorization/AuthorizeService';
import './NavMenu.css';

import { GeneralActions } from './Layout';

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';

export const NavMenu = ({ showMenu }) => {
	// static displayName = NavMenu.name;
	const { toggleShowMenu } = useContext(GeneralActions);

	const location = useLocation();

	const [state, setState] = useState(true)
	const [linkActivo, setlinkActivo] = useState("/")
	const [authenticated, setAuthenticated] = useState(false);

	// constructor(props) {
	// 	super(props);

	// 	this.toggleNavbar = this.toggleNavbar.bind(this);
	// 	this.state = {
	// 		collapsed: true,
	// 		location
	// 	};
	// }
	useEffect(() => {
		authService.isAuthenticated().then(auth => setAuthenticated(auth));
	}, [])

	useEffect(() => {
		setlinkActivo(location.pathname)
	}, [location])


	const toggleNavbar = () => setState(!state);


	return (
		<header style={{ zIndex: 999 }}>
			{
				showMenu &&
				<Navbar 
					style={{ backgroundColor: '#fff' }}
					className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow py-0" 
					light
				>
					<Container>
						<NavbarBrand><img src="./img/LogoSocItalianaPNG.png" alt="logo" className="logoMenu" /></NavbarBrand>
						<NavbarToggler onClick={toggleNavbar} className="mr-2" />
						<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!state} navbar>
							<ul className="navbar-nav flex-grow">
								{authenticated && 
									<>
										<NavItem>
											<NavLink 
												tag={Link} 
												to="/"
												className={`text-dark ${linkActivo==="/" ? "active" : ""}`} 
												data-link-alt="INICIO"
											>
												<span>Inicio</span>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink 
												tag={Link} 
												to="/solicitar-turno-cliente" 
												className={`text-dark ${linkActivo==="/solicitar-turno-cliente" ? "active" : ""}`} 
												data-link-alt="SOLICITAR TURNO"
											>
												<span>Solicitar Turno</span>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink 
												tag={Link} 
												to="/mostrar-turnos-actuales" 
												className={`text-dark ${linkActivo==="/mostrar-turnos-actuales" ? "active" : ""}`} 
												data-link-alt="VER TURNO ACTUAL"
											>
												<span>Ver Turno Actual</span>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink 
												tag={Link} 
												to="/consulta-turnos" 
												className={`text-dark ${linkActivo==="/consulta-turnos" ? "active" : ""}`} 
												data-link-alt="CONSULTA"
											>
												<span>Consulta</span>
											</NavLink>
										</NavItem>
									</>
								}
								<LoginMenu></LoginMenu>
							</ul>
						</Collapse>
					</Container>
				</Navbar>
			}

			{
				authenticated &&
				<button 
					title={showMenu ? 'Cerrar Menu' : 'Abrir Menu'} 
					onClick={toggleShowMenu} 
					className={`btn-toggle-menu ${showMenu ? '' : 'open'}`}
				>
					{showMenu ? <ArrowUpIcon width={25} /> : <ArrowDownIcon width={25} />}
				</button>
			}
			
		</header>
	);
}