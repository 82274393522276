import React, { useState, useEffect, createContext } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import bgImage from '../assets/img/LogoSocItalianaByN.jpg';

import { FullScreen, useFullScreenHandle } from "react-full-screen";

export const GeneralActions = createContext();

export function Layout({children, ocultarMenu}){
	// static displayName = Layout.name;

	const handleFullScreen = useFullScreenHandle();

	const [showMenu, setShowMenu] = useState(true);
	const toggleShowMenu = () => setShowMenu(!showMenu);

	const [showBgImage, setShowBgImage] = useState(false);
	const handleShowBgImage = (show = false) => setShowBgImage(show);
	
	const setFullScreen = (active = true) => active ? handleFullScreen.enter() : handleFullScreen.exit();

	const stylesContainer = {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundImage: `url(${bgImage})`, 
		backgroundSize: 100,
		filter: 'opacity(0.5)',
		backgroundPosition: 'center',
		zIndex: -1
	}

	return (
		<div >
			{showBgImage && <div style={stylesContainer}></div>}
			<GeneralActions.Provider value={{ toggleShowMenu, handleShowBgImage, setFullScreen, activeFullScreen: handleFullScreen.active  }}>
				<NavMenu showMenu={showMenu} />
				<FullScreen handle={handleFullScreen}>
					<Container>
						{children}
					</Container>
				</FullScreen>
			</GeneralActions.Provider>			
		</div>
	);
}
